import { Bar } from 'vue-chartjs';
import { COLOR_PALETTE } from '@/settings/Charts';

export default {
	extends: Bar,
	props: {
		dataImport: {
			type: Array,
			default: () => [],

		},
		labelAppend: {
			type: String,
			default: '',
		},
		labelPrepend: {
			type: String,
			default: '',
		},
		cutoutPercentage: {
			type: Number,
			default: 0,
		},
		colors: {
			type: Array,
			default: () => COLOR_PALETTE,
		},
		// Check the docs for more options
		// https://www.chartjs.org/docs/2.9.4/general/options.html
		chartOptions: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			data: {
				labels: [],
				datasets: [],
			},
			options: {
				legend: {
					display: false,
				},
				hoverBorderWidth: 20,
				responsive: true,
				scales: {
					yAxes: [{
						display: true,
						ticks: {
							beginAtZero: true,
						},
					}],
				},
				tooltips: {
					callbacks: {
						label: (tooltipItem, data) => {
							let label = data.labels[tooltipItem.index];
							let val = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

							if (data.texts && data.texts.length > 0) {
								val = data.texts[tooltipItem.index];
							}

							if (label) {
								label += `: ${val}`;
							}
							return `${this.labelPrepend} ${label} ${this.labelAppend}`;
						},
					},
				},
			},
		};
	},
	computed: {
		graphData() {
			return this.dataImport.map((element) => element.val);
		},
		graphLabels() {
			return this.dataImport.map((element) => element.trans);
		},
		graphText() {
			return this.dataImport.map((element) => element.text);
		},
	},
	watch: {
		chartOptions: {
			handler() {
				this.renderChart(this.data, { ...this.options, ...this.chartOptions });
			},
			deep: true,
		},
	},
	mounted() {
		this.data.labels = this.graphLabels;
		this.data.texts = this.graphText;
		this.options.cutoutPercentage = this.cutoutPercentage;
		this.fillData();
		this.renderChart(this.data, { ...this.options, ...this.chartOptions });
	},
	methods: {
		fillData() {
			const newData = {
				backgroundColor: this.colors,
				data: this.graphData,
			};
			this.data.datasets.push({ ...newData });
		},
	},
};

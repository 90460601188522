<template>
	<div class="animated fadeIn">
		<div class="row">
			<div class="col">
				<div
					:class="!showFilters ? 'btn-primary' : 'btn-secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters;">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</div>
				<div class="btn btn-lg">
					{{ datePeriod }}
				</div>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div class="col-3">
						<div
							class="form-group">
							<label
								for="dateRange"
								class="label">{{ translate('date_range') }}</label>
							<select
								id="dateRange"
								v-model="dateRange"
								name="dateRange"
								class="form-control">
								<option
									v-for="dateRangeInfo in dateRanges"
									:key="dateRangeInfo"
									:value="dateRangeInfo">
									{{ translate(dateRangeInfo) }}
								</option>
							</select>
						</div>
					</div>
					<div
						class="col-3">
						<div class="form-group">
							<label
								for="countryName"
								class="label">{{ translate('country') }}</label>

							<select
								id="countryName"
								v-model="filters.country"
								:disabled="staffCountry"
								name="country"
								class="form-control">
								<template v-if="!staffCountry">
									<option :value="undefined">
										{{ translate('all') }}
									</option>
									<option
										v-for="country in countries"
										:key="country.attributes.code"
										:value="country.attributes.code">
										{{ translate(country.attributes.code.toLowerCase()) }}
									</option>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
				</div>
				<div class="row">
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="selectedStartYear"
								class="label">{{ translate('start_year') }}</label>
							<select
								id="selectedStartYear"
								v-model="selectedStartYear"
								name="selectedStartYear"
								class="form-control">
								<option
									v-for="year in years"
									:key="year"
									:value="year">
									{{ year }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="selectedStartMonth"
								class="label">{{ translate('start_month') }}</label>
							<select
								id="selectedStartMonth"
								v-model="selectedStartMonth"
								name="selectedStartMonth"
								class="form-control">
								<option
									v-for="index in 12"
									:key="index"
									:value="index">
									{{ $moment(index,'MM').format(monthFormat) }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="selectedEndYear"
								class="label">{{ translate('end_year') }}</label>
							<select
								id="selectedEndYear"
								v-model="selectedEndYear"
								name="selectedEndYear"
								class="form-control">
								<option
									v-for="year in years"
									:key="year"
									:value="year">
									{{ year }}
								</option>
							</select>
						</div>
					</div>
					<div
						v-if="dateRange === dateRanges.custom"
						class="col-sm">
						<div
							class="form-group">
							<label
								for="selectedEndMonth"
								class="label">{{ translate('end_month') }}</label>
							<select
								id="selectedEndMonth"
								v-model="selectedEndMonth"
								name="selectedEndMonth"
								class="form-control">
								<option
									v-for="index in 12"
									:key="index"
									:value="index">
									{{ $moment(index,'MM').format(monthFormat) }}
								</option>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<div
			class="row mb-2 d-flex text-right">
			<div class="col-md-6 col-lg-3">
				<icon-card-widget
					small-icon="fa fa-exclamation-circle"
					:big-label="customerLifetimeValue"
					:small-label="translate('customer_lifetime_value')"
					:tooltip="translate('customer_lifetime_value_info')" />
			</div>
			<div class="col-md-6 col-lg-3">
				<icon-card-widget
					small-icon="fa fa-exclamation-circle"
					:big-label="averageOrder"
					:small-label="translate('avg_order')"
					:tooltip="translate('avg_order_info')" />
			</div>
			<div class="col-md-6 col-lg-3">
				<icon-card-widget
					small-icon="fa fa-exclamation-circle"
					:big-label="averageCustomerLifetime"
					:small-label="translate('avg_customer_lifetime')"
					:tooltip="translate('avg_customer_lifetime_info')" />
			</div>
			<div class="col-md-6 col-lg-3">
				<icon-card-widget
					small-icon="fa fa-exclamation-circle"
					:big-label="purchaseFrequency"
					:small-label="translate('purchase_frequency_no_dim')"
					:tooltip="translate('purchase_frequency_info')" />
			</div>
		</div>
		<b-row>
			<b-col class="col-12">
				<b-tabs v-model="tabIndex">
					<b-tab
						v-for="(tabInfo, index) in tabs"
						:key="index">
						<template slot="title">
							<router-link
								:class="$route.name === tabInfo.name ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
								:to="{ name: tabInfo.name, query }"
								class="list-group-item btn text-left">
								{{ translate(tabInfo.translate_key) }}
							</router-link>
						</template>
					</b-tab>
				</b-tabs>
			</b-col>
		</b-row>
		<div
			v-if="!loading && hasLifetimeValuesData && $route.name === 'LifetimeValueCustomers'"
			class="row">
			<div class="col">
				<template>
					<div class="row row-eq-height">
						<div
							v-for="element in lifetimeValuesKeys"
							:key="element"
							class="col-12 col-md-6 mb-3">
							<div class="card h-100 mb-0">
								<div class="card-header bg-dark">
									<h5 class="mb-0">
										{{ translate(element) }}
									</h5>
								</div>
								<div class="card-body h-100 p-3">
									<bar-chart
										:height="200"
										class="mb-2"
										:data-import="ordersData(element)" />
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<div
			v-if="!loading && hasLifetimeValuesData && $route.name === 'LifetimeValueGuests'"
			class="row">
			<div class="col">
				<template>
					<div class="row row-eq-height">
						<div
							v-for="element in lifetimeValuesKeys"
							:key="element"
							class="col-12 col-md-6 mb-3">
							<div class="card h-100 mb-0">
								<div class="card-header bg-dark">
									<h5 class="mb-0">
										{{ translate(element) }}
									</h5>
								</div>
								<div class="row">
									<div class="col d-flex justify-content-center align-items-center mt-3">
										<h2 style="font-size: 4rem">
											{{ orderValue(element) }}
										</h2>
									</div>
								</div>
								<div class="row justify-content-center mb-3">
									<div class="col-auto">
										<h4>
											{{ orderDimension(element) }}
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
		<is-loading
			v-else
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasLifetimeValuesData" />
	</div>
</template>
<script>
import BarChart from '@/components/BarChart';
import {
	Report, Countries, LifetimeValue as LifetimeValueMessages,
} from '@/translations';
import FiltersParams from '@/mixins/FiltersParams';
import {
	MONTHLY_FILTERS as dateRanges,
	DATE_START_FILTER as dateStartFilter,
	DATE_END_FILTER as dateEndFilter,
	MDY_FORMAT,
	YMD_FORMAT,
	allTime,
	custom,
	LIFETIME_VALUE_START_YEAR,
	MMMM_FORMAT as monthFormat,
} from '@/settings/Dates';
import DataFilter from '@/components/DataFilter';
import Country from '@/util/Country';
import WindowSizes from '@/mixins/WindowSizes';
import Reports from '@/util/Report';
import IconCardWidget from '@/components/IconCardWidget';

const countries = new Country();

export default {
	name: 'LifetimeValue',
	messages: [Report, Countries, LifetimeValueMessages],
	components: { DataFilter, BarChart, IconCardWidget },
	mixins: [FiltersParams, WindowSizes],
	data() {
		return {
			dateRanges,
			allTime,
			custom,
			Reports: new Reports(),
			dateRange: dateRanges.current_year,
			rangeStart: '',
			rangeEnd: '',
			tabIndex: 0,
			tabs: [
				{
					name: 'LifetimeValueCustomers',
					translate_key: 'customers',
				},
				{
					name: 'LifetimeValueGuests',
					translate_key: 'guests',
				},
			],
			countries: [],
			lifetimeValues: new Reports(),
			lifetimeValuesKeys: ['orders_qty', 'avg_per_order', 'avg_per_customer', 'purchase_frequency'],
			selectedStartYear: this.$moment().year(),
			selectedStartMonth: this.$moment().month(),
			selectedEndYear: this.$moment().year(),
			selectedEndMonth: this.$moment().month(),
			monthFormat,
		};
	},
	computed: {
		query() {
			return this.$route.query;
		},
		datePeriod() {
			return this.getDatePeriod();
		},
		loading() {
			try {
				return !!this.lifetimeValues.data.loading;
			} catch (error) {
				return false;
			}
		},
		customerLifetimeValue() {
			try {
				const { customer_lifetime_value: customerLifetimeValue } = this.lifetimeValues.data.response.data.meta;
				return this.translate('usd', { amount: customerLifetimeValue.customer_lifetime_value });
			} catch (error) {
				return this.translate('usd', { amount: 0 });
			}
		},
		averageOrder() {
			try {
				const { avg_order_value: avgOrderValue } = this.lifetimeValues.data.response.data.meta;
				return this.translate('usd', { amount: avgOrderValue.avg_order_value });
			} catch (error) {
				return this.translate('usd', { amount: 0 });
			}
		},
		averageCustomerLifetime() {
			try {
				const { avg_customer_lifetime: avgCustomerLifetime } = this.lifetimeValues.data.response.data.meta;
				const monthsNumber = parseInt(avgCustomerLifetime.avg_customer_lifetime, 0);
				const month = monthsNumber === 1 ? 'month' : 'months';
				return this.translate(month, { value: monthsNumber });
			} catch (error) {
				return this.translate('months', { value: 0 });
			}
		},
		purchaseFrequency() {
			try {
				const { purchase_frequency: purchaseFrequency } = this.lifetimeValues.data.response.data.meta;
				const ordersNumber = parseInt(purchaseFrequency.purchase_frequency, 0);
				const order = ordersNumber === 1 ? 'order_month' : 'orders_month';
				return this.translate(order, { value: ordersNumber });
			} catch (error) {
				return this.translate('orders', { value: 0 });
			}
		},
		lifetimeValueData() {
			try {
				const { data } = this.lifetimeValues.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasLifetimeValuesData() {
			return !!this.ordersData.length;
		},
		years() {
			const startingYear = LIFETIME_VALUE_START_YEAR;
			const endingYear = this.$moment().year();
			const years = [];
			for (let index = startingYear; index <= endingYear; index += 1) {
				years.push(index);
			}
			return years;
		},
		staffCountry() {
			return this.$user.details().country !== undefined;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
	},
	mounted() {
		countries.getCountries().then((response) => {
			this.countries = response;
		});

		if (typeof this.$route.name !== 'undefined') {
			this.tabIndex = this.tabs.findIndex((tabInfo) => tabInfo.name === this.$route.name);
		}
		const { query } = this.$route;
		const dateKeys = Object.keys(query);
		if (typeof this.dateRanges[query.dateRange] !== 'undefined') {
			this.dateRange = query.dateRange;
			if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
				const startDate = new Date(query.startDate);
				const endDate = new Date(query.endDate);
				this.selectedStartYear = startDate.getUTCFullYear();
				this.selectedStartMonth = startDate.getUTCMonth() + 1;
				this.selectedEndYear = endDate.getUTCFullYear();
				this.selectedEndMonth = endDate.getUTCMonth() + 1;
			}
		} else if (dateKeys.includes(dateStartFilter) || dateKeys.includes(dateEndFilter)) {
			this.dateRange = this.dateRanges.custom;
		} else if (typeof query.dateRange !== 'undefined' && !Object.values(this.dateRanges).includes(query.dateRange)) {
			this.dateRange = this.dateRanges.current_year;
			query.dateRange = this.dateRanges.current_year;
		} else if (typeof query.dateRange === 'undefined') {
			this.dateRange = this.dateRanges.current_year;
		}

		this.getLifetimeValues();
		if (this.staffCountry) {
			this.filters.country = this.countryCode;
		}
		return null;
	},
	methods: {
		ordersData(type) {
			try {
				const orders = this.lifetimeValueData[type];
				const data = [
					this.formattedData(orders, 'total'),
					this.formattedData(orders, 'purchase'),
					this.formattedData(orders, 'autoship'),
				];
				return data;
			} catch (error) {
				return [];
			}
		},
		formattedData(order, key) {
			const text = order.dimension === 'USD' ? { amount: order[key][key] } : { value: order[key][key] };
			const trans = key === 'purchase' ? 'manual_order' : key;
			return {
				text: `${this.translate(order.dimension.toLowerCase(), text)}`,
				val: order[key][`numeric_${key}`],
				trans: this.translate(trans),
			};
		},
		getLifetimeValues() {
			const type = this.$route.name === 'LifetimeValueCustomers' ? 'customer' : 'guest';
			this.lifetimeValues.getLifetimeValues(type, this.filters).then(() => {
				const { start, end } = this.lifetimeValues.data.response.data.meta.period_dates;
				this.rangeStart = this.$moment(start.date).format(MDY_FORMAT);
				this.rangeEnd = this.$moment(end.date).format(MDY_FORMAT);
			});
		},
		orderValue(type) {
			try {
				const amount = this.lifetimeValueData[type].purchase.purchase;
				if (this.lifetimeValueData[type].dimension === 'USD') return this.translate('usd', { amount }).replace(' USD', '');
				return amount;
			} catch (error) {
				return 0;
			}
		},
		orderDimension(type) {
			try {
				const { dimension } = this.lifetimeValueData[type];
				if (dimension === 'USD') return dimension;
				return this.translate(dimension, { value: '' });
			} catch (error) {
				return '';
			}
		},
		getDataFiltered() {
			if (this.dateRange === custom) {
				const startDate = new Date(this.selectedStartYear, (this.selectedStartMonth - 1), 1);
				const endDate = new Date(this.selectedEndYear, this.selectedEndMonth, 0);
				this.filters.startDate = this.$moment(startDate).format(YMD_FORMAT);
				this.filters.endDate = this.$moment(endDate).format(YMD_FORMAT);
			}

			const { query } = this.$route;
			const options = { ...query, ...this.filters };

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options });
		},
		getDatePeriod() {
			const start = this.rangeStart;
			const end = this.rangeEnd;
			if (start !== '' && end !== '') {
				return this.translate('date_period', { start, end });
			}

			if (start !== '') {
				return this.translate('date_period_from', { start });
			}

			if (end !== '') {
				return this.translate('date_period_until', { end });
			}
			return '';
		},
	},
};
</script>
